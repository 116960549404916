import React from 'react'
import styled from 'styled-components'
import { Container } from '../global'

const Header = () => {
  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>Software for Business</h1>
            <p>
              We're a software company that builds SaaS products and mobile apps
              to help other small businesses run more efficiently.
            </p>
          </HeaderTextGroup>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.background.dark};
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;
  color: #ffffff;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: white;
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  color: white;
  text-align: center;
`
