import React from "react"
import styled from "styled-components"
import ben from "../../images/ben.jpeg"
import shawn from "../../images/shawn.jpeg"
import { Section, Container } from "../global"

const profiles = [
  {
    id: "ben",
    name: "Ben Podoll",
    title: "Partner / Co-Founder",
    email: "ben.podoll@codeswyft.com",
    image: ben,
  },
  {
    id: "shawn",
    name: "Shawn Flahave",
    title: "Partner / Co-Founder",
    email: "shawn.flahave@codeswyft.com",
    image: shawn,
  },
]

const About = () => (
  <Section id="about">
    <StyledContainer>
      <SectionTitle>About Us</SectionTitle>
      <Text>
        CodeSwyft was founded in 2010 by Shawn Flahave and Ben Podoll. In the early days, we worked
        mainly as consultants helping other internet startups bring their ideas to life or to
        streamline their technical operations. Today we're focused on building our own SaaS
        offerings such as <a href="https://www.fielderapp.com">Fielder</a>.
      </Text>
      <Profiles>
        {profiles.map((p) => (
          <ProfileContainer>
            <ProfileImageWrapper>
              <img alt={p.name} src={p.image} />
            </ProfileImageWrapper>
            <ProfileTextWrapper>
              <ProfileName>{p.name}</ProfileName>
              <ProfileTitle>{p.title}</ProfileTitle>
              <ProfileEmail>
                <a href={`mailto:${p.email}`}>{p.email}</a>
              </ProfileEmail>
            </ProfileTextWrapper>
          </ProfileContainer>
        ))}
      </Profiles>
    </StyledContainer>
  </Section>
)

export default About

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: flex-start;
  margin: 0 auto 40px;
  text-align: left;
`

const Text = styled.p`
  line-height: 1.7rem;
`

const Profiles = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: auto;
    grid-gap: 10px;
  }
`

const ProfileContainer = styled.div`
  text-align: center;
  margin-top: 30px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const ProfileImageWrapper = styled.div`
  img {
    width: 200px;
    height: auto;
    border: 1px solid;
    border-radius: 100px;
  }
`

const ProfileTextWrapper = styled.div`
  margin-top: 20px;
`

const ProfileName = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 8px;
`

const ProfileTitle = styled.div`
  font-size: 1.3rem;
  margin-bottom: 8px;
`

const ProfileEmail = styled.div``
