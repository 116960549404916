import React from 'react'
import styled from 'styled-components'
import { Section, Container } from '../global'

const Contact = () => (
  <Section id="contact">
    <StyledContainer>
      <SectionTitle>Contact Us</SectionTitle>
      <FieldWrapper>
        <FieldLabel>Email:</FieldLabel>
        <FieldValue>
          <a href="mailto:info@codeswyft.com">info@codeswyft.com</a>
        </FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Phone:</FieldLabel>
        <FieldValue>
          <a href="tel:6126882176">(612) 688-2176</a>
        </FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Mail:</FieldLabel>
        <FieldValue>
          940 44th Ave NE, #21101
          <br />
          Minneapolis, MN 55421
        </FieldValue>
      </FieldWrapper>
    </StyledContainer>
  </Section>
)

export default Contact

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: flex-start;
  margin: 0 auto 40px;
  text-align: left;
`

const FieldWrapper = styled.div`
  margin-bottom: 20px;
`

const FieldLabel = styled.label`
  font-weight: 900;
`

const FieldValue = styled.div`
  font-weight: 900;
  margin-top: 8px;
`
