import React from "react"
import styled from "styled-components"
import fielderLogo from "../../images/fielder-logo.png"
import { Section, Container } from "../global"

const Work = () => (
  <Section id="work">
    <StyledContainer>
      <SectionTitle>Our Work</SectionTitle>
      <ProjectContainer>
        <TextWrapper>
          <ProjectText>
            We're currently working on a system for field-service franchise businesses we're calling{" "}
            <a href="https://www.fielderapp.com">Fielder</a>. Fielder helps streamline key
            communications between franchisors and their franchisees and standardize certain
            business workflows. It provides a lot of functionality to help franchisees run their
            business such as issuing quotes, scheduling jobs, generating invoices, managing
            inventory, and more.
          </ProjectText>
        </TextWrapper>
        <ProjectImageWrapper>
          <a href="https://www.fielderapp.com">
            <img alt="Fielder logo" src={fielderLogo} />
          </a>
        </ProjectImageWrapper>
      </ProjectContainer>
    </StyledContainer>
  </Section>
)

export default Work

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: flex-start;
  margin: 0 auto 40px;
  text-align: left;
`

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ProjectImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 63px;
  img {
    height: 60px;
    width: auto;
  }
`

const TextWrapper = styled.div`
  max-width: 50%;
  margin-right: 30px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
  }
`

const ProjectText = styled.p`
  line-height: 1.7rem;
`
