import React from 'react'
import Layout from '../components/common/layout/layout'
import SEO from '../components/common/layout/seo'
import Navigation from '../components/common/navigation/navigation'
import Header from '../components/sections/Header'
import Work from '../components/sections/Work'
import Contact from '../components/sections/Contact'
import About from '../components/sections/About'
import Footer from '../components/sections/Footer'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <Header />
    <Work />
    <About />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
