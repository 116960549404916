import React from 'react'
import styled from 'styled-components'
import HeaderLogo from '../common/HeaderLogo'
import { Container } from '../global'

const Footer = () => (
  <FooterWrapper id="footer">
    <BrandContainer>
      <div style={{ width: 200 }}>
        <HeaderLogo />
      </div>
    </BrandContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.background.dark};
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`

export default Footer
